<template>
	<nk-box v-if="context" variant="sidebar">
		<nk-meta size="base" color="neutral-high-contrast">
			Ihr Ansprechpartner
		</nk-meta>
		<hr />
		<nk-link class="group" :link="context.detailURL" block>
			<nk-title
				size="lg"
				class="!text-primary-text-high-contrast group-hover:!text-primary-text-high-contrast--hover"
			>
				{{ context.vorname }}
				{{ context.nachname }}
			</nk-title>
		</nk-link>

		<nk-address v-bind="context.kontakt" />
	</nk-box>
</template>

<script>
import TemplatesDefault from '#nk/mixins/templates-default';

/**
 * @prop {object} context
 */
export default {
	name: 'NkSidebarAnsprechpartner',

	mixins: [TemplatesDefault],
};
</script>
